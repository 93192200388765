<template>
  <section class="container">
    <card title="Login" classes="narrow">
      <div v-if="sending">
          Logging in...
      </div>
      <div v-else>
        <form @submit="login" class="form">
          <div class="form-confirmation note" 
               v-if="confirmed">
            Welcome! Your email has been successfully confirmed. Login below.
          </div>
          Username
          <input type="text" v-model="loginForm.identifier" autofocus/>
          Password
          <input type="password" v-model="loginForm.password" />
          <button type="submit">Login</button>
          <div v-if="error" class="note error">
            {{ error }}
          </div>
        </form>
        <a href="/register">Register as New User</a>
      </div>
    </card>
  </section>
</template>

<script>
import Card from '~/components/Card';

export default {
  components: {
    Card
  },
  data() {
    return {
      sending: false, 
      error: undefined,
      confirmed: this.$route.query.confirmed,
      loginForm: {
        identifier: '',
        password: ''
      }
    };
  },
  methods: {
    login() {
      this.sending = true;

      this.$auth.loginWith('local', { data: this.loginForm })
        .then(response => {
          this.sending = false;
        })
        .catch(error => {
          this.sending = false;

          let message = "An error occurred logging in. Please try again later.";

          console.error(error);

          if (error.response && error.response.data && error.response.data.message) {
            message = error.response.data.message.map(m => m.messages.map(m2 => m2.message).join('\n')).join('\n');
          } else {
            message = 'A server error occurred logging in.';
          }

          this.error = message;
        })
    }
  }
}
</script>
